export default {
  name: 'users',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {
    users () {
      return this.$store.state.users
    }
  },
  mounted () {
    console.log("users mounted")

    if(!this.users.length) this.$store.dispatch('getUsers')

  },
  methods: {

  }
}


