import {db} from '../../main'

export default {
  name: 'user',
  components: {},
  props: [],
  data () {
    return {
      userData: null,
      userDataSecure: null,
      accountsIsownerOf: [],
      accountsIsTeammateIn: []
    }
  },
  computed: {

  },
  mounted () {
    console.log("user view mounted")
    if(!this.userData) this.getUserData()

  },
  methods: {
    async getUserData() {
      this.userData = await this.$store.dispatch('getUser', this.$route.params.user)

      this.userDataSecure = await this.$store.dispatch('getSecureUser', this.$route.params.user)

      this.getAccounts()
    },
    async getAccounts () {
      // getting the accounts that the user has access to
      let query = db.collection(`accounts`)
					.where("teammates", "array-contains", this.userData.id)
          .limit(20)
      
      let collection = await query.get()

      if(!collection.empty){

        for (let index = 0; index < collection.docs.length; index++) {
          let document = collection.docs[index]
          let account = document.data();
          account.id = document.id;

          if(account.owner === this.userData.id){
            this.accountsIsownerOf.push(account)
          } else {
            this.accountsIsTeammateIn.push(account);	
          }

        }

      } else {
        console.log("user has no access to any accounts")
      }

    }
  }
}


